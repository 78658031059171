<template>
  <div id="distributed_ledger">
    <div class="top">
      <img :src="goods.filePath" alt="" v-if="goods.filePath" />
      <img src="@/assets/images/error.png" alt="" v-else />
      <ul>
        <li class="name">
          <p>
            商品名称：&nbsp;&nbsp;&nbsp;&nbsp;<span>{{
              goods.commodityName
            }}</span>
          </p>
          <!-- <p>
            商品名称：&nbsp;&nbsp;&nbsp;&nbsp;<span>正山堂特制金骏眉红茶</span>
          </p> -->
        </li>
        <li class="price">
          <p>建议零售价：</p>
          <i v-if="price">￥</i>
          <span v-if="price">{{ price | Price }}</span>
          <p style="color: #333" v-if="goods && !price">暂无</p>
          <!-- <span>980.00</span> -->
        </li>
      </ul>
    </div>
    <div class="content">
      <ul>
        <li v-for="list in nav" :key="list.text">
          <img :src="list.img" alt="" />
          <span>{{ list.text }}</span>
          <a :href="list.a" v-if="list.a"></a>
          <a v-else @click="tips"></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
//?id=7B3434CB17C247538679CE4BEF51D83A
import { Toast } from "vant";
export default {
  name: "distributed-ledger",
  data() {
    return {
      goods: "",
      price: 0,
      gc: "",
      nav: [
        {
          text: "商品详情",
          a: "",
          img: require("@/assets/images/zd_xq.png"),
        },
        {
          text: "生产厂家",
          // a: "http://www.lapsang.cn/Mobile/",
          a: "",
          img: require("@/assets/images/zd_cj.png"),
        },
        {
          text: "电子保单",
          a: "#/InsurancePolicy",
          img: require("@/assets/images/zd_bx.png"),
        },
        {
          text: "原产地",
          a: "#/GeographicalIndications",
          img: require("@/assets/images/zd_cd.png"),
        },
        {
          text: "检测信息",
          a: "#/InspectionInformation",
          img: require("@/assets/images/zd_jc.png"),
        },
        {
          text: "物流轨迹",
          a: "#/LogisticsTrajectory",
          img: require("@/assets/images/zd_wl.png"),
        },
        {
          text: "赋码防伪",
          a: "#/AntiFake",
          img: require("@/assets/images/zd_fm.png"),
        },
        {
          text: "了解我们",
          a: "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzAwOTM1MDc2MQ==#wechat_redirect",
          img: require("@/assets/images/zd_wm.png"),
        },
        // {},
      ],
    };
  },
  filters: {
    Price(num) {
      if (num === null) {
        return "-";
      } else if (String(Number(num)).indexOf(".")) {
        // return num;
        return num.toFixed(2);
      } else {
        return num;
      }
    },
  },
  created() {
    sessionStorage.getItem("goods") && (this.show = false);
    this.goodsDetails();
    sessionStorage.setItem("id", this.$route.query.id);
  },
  methods: {
    //单品详情
    async goodsDetails() {
      const data = await this.$API.goods.getGoods({
        object: {
          id: this.$route.query.id,
          // this.$route.query.id
        },
      });
      if (data.data.data) {
        this.goods = data.data.data;

        this.goods.filePath = this.goods.filePath.split(",")[0].endsWith(".mp4")
          ? this.goods.filePath.split(",")[1]
          : this.goods.filePath.split(",")[0];

        this.nav[0].a = `#/goodsDetails?barcode=${this.goods.barcode}&enterpriseId=${this.goods.enterpriseId}`;
        sessionStorage.setItem("goods", JSON.stringify(this.goods));
        this.goodsPrice();
        this.getFactoryList();
      } else {
        sessionStorage.clear();
      }
    },
    //商品价格
    async goodsPrice() {
      const data = await this.$API.goods.getGoodsPrice({
        object: {
          barcode: this.goods.barcode,
          enterpriseId: this.goods.enterpriseId,
        },
      });
      // data.data.data = null;
      if (data.data.data) this.price = data.data.data.retailPrice;
      sessionStorage.setItem("price", this.price);
    },

    async getFactoryList() {
      const data = await this.$API.trackBack.getFactoryList({
        object: {
          enterpriseId: this.goods.enterpriseId,
        },
        pageNum: 1,
        pageSize: 1,
      });
      this.gc = data.data.data[0];
      if (this.gc) this.nav[1].a = this.gc.companyUrl;
    },
    tips() {
      Toast("暂无网址");
    },
  },
};
</script>

<style lang="scss" scoped>
#distributed_ledger {
  padding: 13px 10px;
  display: flex;
  flex-direction: column;
  .top {
    background: #ffffff;
    display: flex;
    padding: 15px 12px;
    border-radius: 8px;
    img {
      width: 70px;
      height: 70px;
      border-radius: 6px;
      margin-right: 12px;
      flex-shrink: 0;
    }
    ul {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      li {
        display: flex;
        p {
          color: #888888;
        }
      }
      .name span {
        color: #333333;
      }

      .price {
        color: #fc2d14;
        line-height: 19px;
        span {
          font-weight: 600;
        }
        i {
          font-style: normal;
          font-size: 10px;
          transform: scale(0.83333);
          transform-origin: 0 0;
          position: relative;
          bottom: -3px;
          right: -2px;
        }
      }
    }
  }
  .content {
    background-color: #ffffff;
    margin-top: 15px;
    border-radius: 8px;
    padding: 0 6px;
    ul {
      display: grid;
      justify-content: space-evenly;
      grid-template-columns: repeat(auto-fill, 108px);
      // grid-gap: 10px;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // width: 87px;
        height: 109px;
        background: #ffffff;
        box-shadow: 0px 2px 3px 1px rgba(67, 100, 114, 0.2);
        border-radius: 6px;
        opacity: 0.8;
        position: relative;
        margin: 15px 12px;
        // margin-bottom: 33px;

        img {
          width: 45px;
        }
        span {
          font-size: 13px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #6181eb;
          margin-top: 8px;
        }
        a {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
    // ul:after {
    //   // 使用伪类元素占据单位，不影响页面
    //   content: "";
    //   height: 0;
    //   flex: 1;
    // }
  }
  .none {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      position: relative;
      width: 270px;
      height: 250px;
      text-align: center;
      img {
        width: 100%;
      }
      p {
        position: absolute;
        font-size: 15px;
        color: #b1b1b1;
        top: 192px;
        width: 100%;
      }
    }
  }
  .van-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
